<template>
  <div style="margin-top:80px;">
    <v-container>
      <v-row no-gutters>
        <v-col cols="2" v-if="false">
          <v-navigation-drawer
            permanent
          >
            <ul
              id="sidenav-left" 
              class="sidenav sidenav-fixed"
            >
              <li>
                <h5 class="center">Main menu</h5>
              </li>
            </ul>
            <v-tabs vertical>
              <v-tab v-if="user.data&&user.data.usertype!='Loan Officer'" to="/vizion/dashboard" class="sidenav-item">
                <v-icon left>
                  dashboard
                </v-icon>
                Dashboard
              </v-tab>
              <!-- <v-tab v-if="user.data&&user.data.usertype!='Loan Officer'" to="/vizion/companies" class="sidenav-item">
                <v-icon left>
                  group_work
                </v-icon>
                Companies
              </v-tab> -->
              <v-tab v-if="user.data&&user.data.usertype=='Admin'" to="/vizion/users" class="sidenav-item">
                <v-icon left>
                  person_pin
                </v-icon>
                Users
              </v-tab>
              <v-tab v-if="user.data&&user.data.usertype=='Admin'" to="/vizion/system" class="sidenav-item">
                <v-icon left>
                  person_pin
                </v-icon>
                System
              </v-tab>
              <!-- <v-tab to="/vizion/lenders" class="sidenav-item">
                <v-icon left>
                  attach_money
                </v-icon>
                Lenders
              </v-tab> -->
            </v-tabs>
          </v-navigation-drawer>
        </v-col>
        <v-col>
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'HomeDrawer',
    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      drawer: null,
    }),
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),

    },
    created () {
      // console.log(this.user)
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>

<style scoped>
.sidenav {
  list-style: none;
}
.sidenav h5 {
  font-size:150%;
}
.sidenav-item {
  text-align:left;
  justify-content: left;
}
</style>
